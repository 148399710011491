import React, { CSSProperties, FC, useCallback } from 'react';
import { ShopifyLocalCheckoutLineItem } from '../../../types/api/entities/shopify';
import { ProductNumericInput } from '../../recipe/product-numeric-input';
import { FaTrash } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { removeCheckoutLineItem } from '../../../store/actions/shopify/remove-checkout-line-item';
import { formatVariantPrice } from '../../../util/format-currency';

export interface CartItemProps {
  item: ShopifyLocalCheckoutLineItem;
  setQuantity(variantId: string, quantity: number): void;
  style?: CSSProperties;
}

interface DispatchProps {
  removeCheckoutLineItem(variantId: string): void;
}

const _CartItem: FC<CartItemProps & DispatchProps> = ({
  item,
  setQuantity,
  style,
  removeCheckoutLineItem,
}) => {
  const onQuantityChange = useCallback(
    (quantity: number) => {
      setQuantity(item.variant.id, quantity);
    },
    [item.variant.id, setQuantity],
  );

  const { variant, quantity } = item;

  const image = variant.image?.transformedSrc || variant.product.images[0]?.transformedSrc;

  const onRemoveClick = useCallback(() => {
    removeCheckoutLineItem(item.variant.id);
  }, [removeCheckoutLineItem, item.variant.id]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', ...style }}>
      {image && (
        <div
          style={{
            width: '75px',
            height: '75px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: '6px',
            backgroundImage: `url(${image})`,
            backgroundPosition: 'center',
          }}
        />
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexGrow: 2,
        }}
      >
        <div style={{ marginLeft: '1em' }}>
          <span
            style={{
              fontWeight: 'normal',
              fontSize: '18px',
              display: 'block',
              fontFamily: 'var(--font-family-secondary)',
            }}
          >
            {variant.product.title}
          </span>

          <div
            style={{
              marginTop: '10px',
              fontFamily: 'var(--font-family-primary)',
              fontSize: '16px',
              color: '#424242',
            }}
          >
            <span>{formatVariantPrice(variant)}</span>
          </div>
        </div>

        <div>
          <ProductNumericInput value={quantity} onChange={onQuantityChange} />
        </div>
      </div>

      <span style={{ marginLeft: '1em', cursor: 'pointer' }} onClick={onRemoveClick}>
        <FaTrash color="#424242" />
      </span>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  removeCheckoutLineItem: (variantId: string) => dispatch(removeCheckoutLineItem(variantId)),
});

export const CartItem = connect(undefined, mapDispatchToProps)(_CartItem);
