import React from 'react';
import { Header } from './header/header';
import { Footer } from './footer/footer';
import { Main } from './main/main';

export class Frame extends React.Component {
  public render() {
    return (
      <>
        <Main>
          <Header />
          {this.props.children}
        </Main>
        <Footer />
      </>
    );
  }
}
