import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../../store/state';
import { Dispatch } from 'redux';
import { setSearch } from '../../../store/actions/ui/set-search';
import Router from 'next/router';
import { LinkCreator } from '../../../util/linker';
import { WithTranslation as WithTranslationType } from 'next-i18next';
import { withTranslation } from 'next-i18next';

interface OwnProps {
  style?: CSSProperties;
}

interface StateProps {
  value: string;
}

interface DispatchProps {
  setSearch: (payload: string) => void;
}

type HeaderInputProps = OwnProps & StateProps & DispatchProps & WithTranslationType;

// TODO: Add search icon
class HeaderInputComponent extends React.Component<HeaderInputProps> {
  public render() {
    return (
      <>
        <input
          type="text"
          placeholder={this.props.t('SearchForRecipes')}
          onChange={this.onChange}
          onKeyPress={this.onKeyPress}
          value={this.props.value}
          style={this.props.style}
        />
        <style jsx>{`
          input {
            background-color: #f8f8f8;
            border: none;
            height: 44px;
            width: 100%;
            margin: none;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 0 16px;
            font-family: var(--font-family-primary);
          }

          input::placeholder {
            color: #343434;
            font-family: var(--font-family-primary);
          }
        `}</style>
      </>
    );
  }

  private readonly onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.props.setSearch(e.target.value);
  };

  private readonly onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      Router.push(
        LinkCreator.toSearch({
          search: (e.target as HTMLInputElement).value.trim(),
        }),
      );
    }
  };
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    value: state.ui.search,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    setSearch: (payload) => dispatch(setSearch(payload)),
  };
}

export const HeaderInput = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(HeaderInputComponent),
);
