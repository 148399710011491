export interface SetLineItemQuantityAction {
  type: 'SHOPIFY/SET_LINE_ITEM_QUANTITY';
  payload: {
    variantId: string;
    quantity: number;
  };
}

export function setLineItemQuantity(
  variantId: string,
  quantity: number,
): SetLineItemQuantityAction {
  return {
    type: 'SHOPIFY/SET_LINE_ITEM_QUANTITY',
    payload: {
      variantId,
      quantity,
    },
  };
}
