import { ChangeEvent, FC, useCallback } from 'react';

export interface ProductNumericInputProps {
  value: number;
  onChange(value: number): void;
}

export const ProductNumericInput: FC<ProductNumericInputProps> = ({ value, onChange }) => {
  const onButtonChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.valueAsNumber);
    },
    [onChange],
  );

  const onPlusClick = useCallback(() => {
    onChange(value + 1);
  }, [onChange, value]);

  const onMinusClick = useCallback(() => {
    onChange(value - 1);
  }, [onChange, value]);

  return (
    <>
      <div
        style={{
          display: 'inline-flex',
          height: '30px',
          fontFamily: 'var(--font-family-primary)',
          border: '1px solid #ececec',
        }}
      >
        <button className="button" onClick={onMinusClick}>
          -
        </button>

        <input
          type="number"
          value={value}
          onChange={onButtonChange}
          style={{
            height: '100%',
            width: '30px',
            boxSizing: 'border-box',
            outline: 'none',
            textAlign: 'center',
          }}
        />

        <button className="button" onClick={onPlusClick}>
          +
        </button>
      </div>
      <style jsx>
        {`
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          input[type='number'] {
            -moz-appearance: textfield;
          }

          input {
            outline: none !important;
            border: none;
          }

          .button {
            width: 30px;
            height: 100%;
            outline: none;
            background-color: transparent;
            box-sizing: border-box;
            border: none;
            cursor: pointer;
          }

          .button:hover {
            background-color: #ececec;
          }
        `}
      </style>
    </>
  );
};
