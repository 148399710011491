import React from 'react';
import { Store } from '../../../types/api/entities/store';
import { StoreState } from '../../../store/state';
import { connect } from 'react-redux';

interface StateProps {
  store: Store;
}

type MainProps = StateProps;

class MainComponent extends React.PureComponent<MainProps> {
  public render() {
    return (
      <div>
        {this.props.children}
        <style jsx>{`
          :global(body) {
            background-color: ${this.props.store.theme
              ? this.props.store.theme.mainBackgroundColor
              : 'inherit'};
          }
        `}</style>
      </div>
    );
  }
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    store: state.entities.store,
  };
}

export const Main = connect(mapStateToProps)(MainComponent);
