export interface RemoveCheckoutLineItemAction {
  type: 'SHOPIFY/REMOVE_CHECKOUT_LINE_ITEM';
  payload: {
    variantId: string;
  };
}

export function removeCheckoutLineItem(variantId: string): RemoveCheckoutLineItemAction {
  return {
    type: 'SHOPIFY/REMOVE_CHECKOUT_LINE_ITEM',
    payload: {
      variantId,
    },
  };
}
