export enum ScreenSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  ExtraLarge = 'ExtraLarge',
}

export type MinimumSize = {
  [key in ScreenSize]: number;
};

export type MediaQuery = {
  [key in ScreenSize]: string;
};

export const CONTENT_MAX_WIDTH = '1200px';

export const minimumSize: Readonly<MinimumSize> = {
  Small: 0,
  Medium: 768,
  Large: 1000,
  ExtraLarge: 1440,
};

export const mediaQuery: Readonly<MediaQuery> = {
  Small: `screen and (min-width: 0px)`,
  Medium: `screen and (min-width: ${minimumSize.Medium}px)`,
  Large: `screen and (min-width: ${minimumSize.Large}px)`,
  ExtraLarge: `screen and (min-width: ${minimumSize.ExtraLarge}px)`,
};
