import { PartialRecipe } from '../../types/api/entities/recipe';
import React, { memo } from 'react';
import Link from 'next/link';
import { LinkCreator } from '../../util/linker';
import { RecipeCard } from '@commerceowl/components';

export interface RecipeCardProps {
  recipe: PartialRecipe;
  showRating?: boolean;
}

export const LinkedRecipeCard = memo<RecipeCardProps>(({ recipe, showRating }) => {
  return (
    <Link href={LinkCreator.toRecipe(recipe)} scroll={true}>
      <a style={{ textDecoration: 'none', color: 'inherit' }}>
        <RecipeCard recipe={recipe} showRating={showRating} />
      </a>
    </Link>
  );
});
