export interface SetCartIsOpenAction {
  type: 'SHOPIFY/SET_CART_IS_OPEN';
  payload: {
    cartIsOpen: boolean;
  };
}

export function setCartIsOpen(cartIsOpen: boolean): SetCartIsOpenAction {
  return {
    type: 'SHOPIFY/SET_CART_IS_OPEN',
    payload: {
      cartIsOpen,
    },
  };
}
