export interface SetSearchAction {
  type: 'UI/SET_SEARCH';
  payload: string;
}

export function setSearch(payload: string): SetSearchAction {
  return {
    type: 'UI/SET_SEARCH',
    payload,
  };
}
