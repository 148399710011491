import { PartialRecipe } from '../types/api/entities/recipe';
import { RecipeFilterOption } from '../types/api/entities/recipe-filter';
import * as qs from 'query-string';
import { Store } from '../types/api/entities/store';

interface ToSearchOptions {
  search?: string;
  filter?: RecipeFilterOption;
}

export abstract class LinkCreator {
  public static toRecipe(recipe: PartialRecipe): string {
    return `/recipe/${recipe.slug}`;
  }

  public static absoluteRecipeLink(store: Store, recipe: PartialRecipe): string {
    const relativeRecipeLink = LinkCreator.toRecipe(recipe);
    let url = `https://${store.subdomain}.commerceowl.com${relativeRecipeLink}`;
    if (store.customDomain) {
      url = `https://${store.customDomain}${relativeRecipeLink}`;
    }

    return url;
  }

  public static toSearch(options: ToSearchOptions = {}): string {
    const query = qs.stringify({
      query: options.search,
      filter: options.filter && options.filter.slug,
    });

    return '/search?' + query;
  }

  public static toRoot(): string {
    return '/';
  }
}
