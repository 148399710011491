import { CSSProperties, FC, useCallback, useRef, useState } from 'react';
import { FaChevronRight, FaChevronDown } from 'react-icons/fa';
import { StoreHeaderMenuItem } from '../../../types/api/entities/store';
import { usePopper } from 'react-popper';
import { useRouter } from 'next/router';

export interface HeaderMenuProps {
  items: StoreHeaderMenuItem[];
}

export interface HeaderItemProps {
  item: StoreHeaderMenuItem;
  style?: CSSProperties;
}

export interface HeaderLinkProps {
  item: StoreHeaderMenuItem;
}

const useTwoElementsTooltip = () => {
  const [isVisible, setIsVisible] = useState(false);
  const mouseIsInAnchorRef = useRef(false);
  const mouseIsInTooltipRef = useRef(false);
  const mouseLeaveHandleRef = useRef<ReturnType<typeof setTimeout>>();

  const maybeHide = useCallback(() => {
    if (mouseLeaveHandleRef.current) {
      clearTimeout(mouseLeaveHandleRef.current);
      mouseLeaveHandleRef.current = undefined;
    }

    mouseLeaveHandleRef.current = setTimeout(() => {
      if (!mouseIsInAnchorRef.current && !mouseIsInTooltipRef.current) {
        setIsVisible(false);
      }
    }, 20);
  }, [mouseIsInAnchorRef, mouseIsInTooltipRef, mouseLeaveHandleRef]);

  const onAnchorMouseEnter = useCallback(() => {
    mouseIsInAnchorRef.current = true;
    setIsVisible(true);
  }, [mouseIsInAnchorRef]);

  const onAnchorMouseLeave = useCallback(() => {
    mouseIsInAnchorRef.current = false;
    maybeHide();
  }, [mouseIsInAnchorRef, maybeHide]);

  const onTooltipMouseEnter = useCallback(() => {
    mouseIsInTooltipRef.current = true;
    setIsVisible(true);
  }, [mouseIsInTooltipRef]);

  const onTooltipMouseLeave = useCallback(() => {
    mouseIsInTooltipRef.current = false;
    maybeHide();
  }, [mouseIsInTooltipRef, maybeHide]);

  return {
    isVisible,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    onTooltipMouseEnter,
    onTooltipMouseLeave,
  };
};

interface HeaderSubMenuItemProps {
  item: StoreHeaderMenuItem;
  style?: CSSProperties;
}

const headerSubMenuItemStyle: CSSProperties = {
  padding: '4px 12px',
  whiteSpace: 'nowrap',
  textDecoration: 'none',
  color: '#131415',
  display: 'flex',
  alignItems: 'center',
};

const HeaderSubMenuItem: FC<HeaderSubMenuItemProps> = ({ item, style }) => {
  const router = useRouter();

  const [referenceElement, setReferenceElement] = useState<HTMLAnchorElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right-start',
  });

  const {
    isVisible,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    onTooltipMouseEnter,
    onTooltipMouseLeave,
  } = useTwoElementsTooltip();

  return (
    <>
      <a
        className="menu-item"
        href={item.target.value}
        onClick={(e) => {
          e.preventDefault();
          router.push(item.target.value);
        }}
        ref={setReferenceElement}
        onMouseEnter={onAnchorMouseEnter}
        onMouseLeave={onAnchorMouseLeave}
        style={{
          ...headerSubMenuItemStyle,
          backgroundColor: isVisible ? '#f8f8f8' : undefined,
          fontFamily: 'var(--font-family-primary)',
          ...style,
        }}
      >
        {item.label}

        {item.children && item.children.length > 0 && (
          <FaChevronRight color="#131415" size=".6em" style={{ marginLeft: '8px' }} />
        )}
      </a>

      {item.children && item.children.length > 0 && isVisible && (
        <div
          ref={setPopperElement}
          style={{
            ...styles.popper,
            backgroundColor: 'white',
            borderRadius: '4px',
            boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)',
            zIndex: 1,
          }}
          {...attributes.popper}
          onMouseEnter={onTooltipMouseEnter}
          onMouseLeave={onTooltipMouseLeave}
        >
          {item.children.map((child, index, items) => (
            <a
              className="menu-item"
              href={child.target.value}
              key={child.id}
              onClick={(e) => {
                e.preventDefault();
                router.push(child.target.value);
              }}
              style={{
                ...headerSubMenuItemStyle,
                fontFamily: 'var(--font-family-primary)',
                borderTop: index === 0 ? undefined : '1px solid #e8e8e8',
                borderTopLeftRadius: index === 0 ? '4px' : undefined,
                borderTopRightRadius: index === 0 ? '4px' : undefined,
                borderBottomLeftRadius: index === items.length - 1 ? '4px' : undefined,
                borderBottomRightRadius: index === items.length - 1 ? '4px' : undefined,
              }}
            >
              {child.label}
            </a>
          ))}
        </div>
      )}

      <style jsx>{`
        .menu-item:hover {
          background-color: #f8f8f8;
        }
      `}</style>
    </>
  );
};

const HeaderItem: FC<HeaderItemProps> = ({ item, style }) => {
  const router = useRouter();
  const [referenceElement, setReferenceElement] = useState<HTMLAnchorElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });

  const {
    isVisible,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    onTooltipMouseEnter,
    onTooltipMouseLeave,
  } = useTwoElementsTooltip();

  return (
    <>
      <a
        className="header-item"
        ref={setReferenceElement}
        href={item.target.value}
        onMouseEnter={onAnchorMouseEnter}
        onMouseLeave={onAnchorMouseLeave}
        onClick={(e) => {
          e.preventDefault();
          router.push(item.target.value);
        }}
        style={{
          textDecoration: 'none',
          whiteSpace: 'nowrap',
          color: '#131415',
          display: 'flex',
          alignItems: 'baseline',
          fontFamily: 'var(--font-family-primary)',
          ...style,
        }}
      >
        {item.label}

        {item.children && item.children.length > 0 && (
          <FaChevronDown color="#131415" size=".6em" style={{ marginLeft: '6px' }} />
        )}
      </a>

      {item.children && item.children.length > 0 && isVisible && (
        <div
          ref={setPopperElement}
          style={{
            ...styles.popper,
            boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)',
            display: 'flex',
            backgroundColor: 'white',
            borderRadius: '4px',
            flexDirection: 'column',
            zIndex: 2,
          }}
          {...attributes.popper}
          onMouseEnter={onTooltipMouseEnter}
          onMouseLeave={onTooltipMouseLeave}
        >
          {item.children.map((child, index, items) => (
            <HeaderSubMenuItem
              key={child.id}
              item={child}
              style={{
                borderTop: index === 0 ? undefined : '1px solid #e8e8e8',
                borderTopLeftRadius: index === 0 ? '4px' : undefined,
                borderTopRightRadius: index === 0 ? '4px' : undefined,
                borderBottomLeftRadius: index === items.length - 1 ? '4px' : undefined,
                borderBottomRightRadius: index === items.length - 1 ? '4px' : undefined,
              }}
            />
          ))}
        </div>
      )}
      <style jsx>{`
        .header-item {
          border-radius: 6px;
          border: 2px solid transparent;
          padding: 8px 14px;
        }

        .header-item:hover {
          border: 2px solid #f0ecec;
        }
      `}</style>
    </>
  );
};

export const HeaderMenuLarge: FC<HeaderMenuProps> = ({ items }) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginLeft: '10px',
      }}
    >
      {items.map((item, index) => (
        <HeaderItem
          key={item.id}
          item={item}
          style={index === 0 ? undefined : { marginLeft: '10px' }}
        />
      ))}
    </div>
  );
};
