import React, { FC, useCallback, useState } from 'react';
import { HeaderInput } from './header-input';
import { mediaQuery, CONTENT_MAX_WIDTH } from '../../../util/ui';
import { SignupSource, Store } from '../../../types/api/entities/store';
import { StoreState } from '../../../store/state';
import { connect } from 'react-redux';
import { CartIcon } from './cart-icon';
import { FaBars, FaSearch } from 'react-icons/fa';
import { HeaderMenuLarge } from './header-menu-large';
import { HeaderMenuMobile } from './header-menu-mobile';

interface StateProps {
  store: Store;
}

type HeaderProps = StateProps;

const HeaderComponent: FC<HeaderProps> = ({ store }) => {
  const [searchBarIsVisible, setSearchBarIsVisible] = useState(false);
  const [menuIsVisible, setMenuIsVisible] = useState(false);

  const toggleSearchBarIsVisible = useCallback(() => {
    setSearchBarIsVisible((current) => !current);
  }, []);

  const toggleMenuIsVisible = useCallback(() => {
    setMenuIsVisible((current) => !current);
  }, []);

  const hideMenu = useCallback(() => {
    setMenuIsVisible(false);
  }, []);

  const menuItems = store.headerMenu?.items ?? [];

  return (
    <>
      <div className="header-container">
        <div className="header__content">
          <div className="header__row-1">
            <div className="header__row-1-left">
              <a href={store.website}>
                <div className="header__logo" />
              </a>
            </div>
            <div className="header__buttons">
              <div className="header-menu_large">
                <HeaderMenuLarge items={menuItems} />
              </div>
              <div className="header__search-icon">
                <FaSearch color="#343434" size="20px" onClick={toggleSearchBarIsVisible} />
              </div>
              <div className="header__menu-icon">
                <FaBars color="#343434" size="20px" onClick={toggleMenuIsVisible} />
              </div>
              {store.signupSource === SignupSource.Shopify && (
                <div className="header__shop-button">
                  <CartIcon />
                </div>
              )}
            </div>
          </div>

          {searchBarIsVisible && (
            <div className="header__row-2">
              <HeaderInput />
            </div>
          )}
        </div>
      </div>

      {menuIsVisible && (
        <div className="header-menu_mobile">
          <HeaderMenuMobile items={menuItems} onHide={hideMenu} />
        </div>
      )}
      <style jsx>{`
        .header-container {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .header__content {
          padding-top: 13px;
          padding-bottom: 13px;
          padding-left: 10px;
          padding-right: 10px;
          width: 100%;
          max-width: ${CONTENT_MAX_WIDTH};
        }

        .header__row-1 {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .header__row-1-left {
          display: flex;
          align-items: center;
        }

        .header__row-2 {
          margin-top: 10px;
        }

        .header__logo {
          width: 141px;
          height: 40px;
          background-image: url(${store.logoUrl});
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }

        .header__buttons {
          justify-self: end;
          display: flex;
          align-items: center;
        }

        .header__search-icon {
          cursor: pointer;
        }

        .header__menu-icon {
          margin-left: 10px;
          cursor: pointer;
        }

        .header__shop-button {
          margin-left: 10px;
        }

        .header-menu_large {
          display: none;
        }

        @media ${mediaQuery.Medium} {
          .header__content {
            padding-left: 24px;
            padding-right: 24px;
          }

          .header__menu-icon {
            display: none;
          }

          .header-menu_mobile {
            display: none;
          }

          .header-menu_large {
            display: block;
            margin-right: 30px;
          }

          .header__shop-button {
            margin-left: 30px;
          }
        }

        @media ${mediaQuery.ExtraLarge} {
          .header-container {
            margin: 24px 0;
          }

          .header__content {
            padding: 0;
          }

          .header__logo {
            width: 175px;
            background-position: center;
          }

          .header__button {
            margin-left: 20px;
          }
        }

        @media print {
          .header-container {
            display: none;
          }
        }
      `}</style>
    </>
  );
};

function mapStateToProps(state: StoreState): StateProps {
  return {
    store: state.entities.store,
  };
}

export const Header = connect(mapStateToProps)(HeaderComponent);
