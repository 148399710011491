import React from 'react';
import { mediaQuery, CONTENT_MAX_WIDTH } from '../../../util/ui';
import Router from 'next/router';
import { Store } from '../../../types/api/entities/store';
import { StoreState } from '../../../store/state';
import { connect } from 'react-redux';
import { withTranslation } from 'next-i18next';
import { WithTranslation as WithTranslationType } from 'next-i18next';

interface StateProps {
  store: Store;
}

type FooterProps = StateProps & WithTranslationType;

class FooterComponent extends React.PureComponent<FooterProps> {
  public render() {
    return (
      <div className="footer">
        <div className="footer__content">
          <div className="footer__logo-container">
            <div className="footer__logo" />
          </div>
          <div className="footer__media" style={{ visibility: 'hidden' }}>
            F T I
          </div>
          <nav className="footer__links" style={{ visibility: 'hidden' }}>
            <ul>
              <li>
                <a onClick={this.onClick('/product')}>Product</a>
              </li>
              <li>
                <a onClick={this.onClick('/reviews')}>Reviews</a>
              </li>
              <li>
                <a onClick={this.onClick('/contact')}>Contact</a>
              </li>
              <li>
                <a onClick={this.onClick('/privacy')}>Privacy</a>
              </li>
            </ul>
          </nav>
          {this.props.store.plan?.bucket_name === 'enterprise' ? null : (
            <div className="footer__message">
              <a href="https://www.commerceowl.com" rel="nofollow" target="_blank">
                {this.props.t('ByCommerceOwl')}
              </a>
            </div>
          )}
        </div>
        <style jsx>{`
          a {
            color: inherit;
            text-decoration: none;
          }

          @media screen, print {
            .footer {
              background-color: ${this.props.store.theme
                ? this.props.store.theme.footerBackgroundColor
                : 'rgba(216, 216, 216, 0.1)'};
              display: flex;
              justify-content: center;
            }

            .footer__links,
            .footer__message {
              font-family: var(--font-family-primary);
              color: #424242;
              font-weight: normal;
              line-height: 1.67;
              letter-spacing: normal;
              font-size: 16px;
            }
          }

          @media screen {
            .footer {
              padding-top: 34px;
              padding-bottom: 26px;
            }

            .footer__content {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              max-width: ${CONTENT_MAX_WIDTH};
            }

            .footer__logo {
              height: 44px;
              width: 180px;
              background-image: url(${this.props.store.logoUrl});
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }

            .footer__media {
              margin-top: 33px;
            }

            .footer__links {
              margin-top: 24px;
            }

            .footer__links ul {
              padding: 0;
              margin: 0;
            }

            .footer__links li {
              list-style-type: none;
            }

            .footer__links li:nth-child(n + 2) {
              margin-top: 16px;
            }

            .footer__links a {
              cursor: pointer;
            }

            .footer__message {
              margin-top: 25px;
            }
          }

          @media ${mediaQuery.ExtraLarge} {
            .footer {
              padding-top: 63px;
              padding-bottom: 67px;
            }

            .footer__content {
              flex-direction: row;
              justify-content: space-between;
              flex-wrap: wrap;
            }

            .footer__logo-container,
            .footer__media,
            .footer__links,
            .footer__message {
              width: 50%;
            }

            .footer__logo {
              background-position: left;
            }

            .footer__media,
            .footer__message {
              text-align: right;
            }

            .footer__links,
            .footer__message {
              font-size: 18px;
              margin-top: 70px;
            }

            .footer__media {
              margin-top: 0;
            }

            .footer__links li {
              display: inline-block;
            }

            .footer__links li:nth-child(n + 2) {
              margin-top: 0;
              margin-left: 39px;
            }
          }

          @media print {
            .footer {
              padding-top: 34px;
              padding-bottom: 38px;
            }

            .footer__logo-container,
            .footer__media,
            .footer__links {
              display: none;
            }

            .footer__message {
              font-size: 18px;
            }
          }
        `}</style>
      </div>
    );
  }

  private onClick(url: string) {
    return (event: React.MouseEvent) => {
      event.preventDefault();
      Router.push(url);
    };
  }
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    store: state.entities.store,
  };
}

export const Footer = withTranslation()(connect(mapStateToProps)(FooterComponent));
