import { useRouter } from 'next/router';
import { FC, useCallback, useRef, MouseEvent, useState } from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { StoreHeaderMenuItem } from '../../../types/api/entities/store';

export interface HeaderMenuMobileProps {
  items: StoreHeaderMenuItem[];
  onHide(): void;
}

interface HeaderMenuItemProps {
  item: StoreHeaderMenuItem;
  level?: number;
}

const HeaderMenuItem: FC<HeaderMenuItemProps> = ({ item, level = 1 }) => {
  const router = useRouter();
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleIsExpanded = useCallback(() => {
    setIsExpanded((current) => !current);
  }, []);

  const onAnchorContainerClick = useCallback(() => {
    toggleIsExpanded();
  }, [toggleIsExpanded]);

  const onAnchorClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      router.push(item.target.value);
    },
    [item.target.value, router],
  );

  return (
    <div>
      <div
        className="menu-item"
        style={{
          padding: `8px 12px 8px ${level * 12}px`,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={onAnchorContainerClick}
      >
        <a
          href={item.target.value}
          onClick={onAnchorClick}
          style={{
            textDecoration: 'none',
            color: '#131415',
          }}
        >
          {item.label}
        </a>

        {item.children &&
          item.children.length > 0 &&
          (isExpanded ? (
            <FaChevronDown color="#131415" size=".6em" style={{ marginLeft: '6px' }} />
          ) : (
            <FaChevronRight color="#131415" size=".6em" style={{ marginLeft: '6px' }} />
          ))}
      </div>

      {item.children && item.children.length > 0 && isExpanded && (
        <div>
          {item.children.map((child) => (
            <HeaderMenuItem key={child.id} item={child} level={level + 1} />
          ))}
        </div>
      )}
      <style jsx>{`
        .menu-item:hover {
          background-color: #f8f8f8;
        }
      `}</style>
    </div>
  );
};

export const HeaderMenuMobile: FC<HeaderMenuMobileProps> = ({ items, onHide }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const onContainerClick = useCallback(
    (e: MouseEvent) => {
      if (e.target === containerRef.current) {
        onHide();
      }
    },
    [containerRef, onHide],
  );

  return (
    <div
      ref={containerRef}
      style={{
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        cursor: 'pointer',
        zIndex: 1,
      }}
      onClick={onContainerClick}
    >
      <div
        style={{
          backgroundColor: 'white',
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: '60px',
          maxWidth: '400px',
          boxShadow: '0 8px 20px 0 rgba(0, 0, 0, 0.2)',
          cursor: 'auto',
        }}
      >
        {items.map((item) => (
          <HeaderMenuItem key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
};
