import { withTranslation, WithTranslation } from 'next-i18next';
import React, { FC, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { setLineItemQuantity } from '../../../store/actions/shopify/set-line-item-quantity';
import { StoreState } from '../../../store/state';
import { ShopifyLocalCheckout } from '../../../types/api/entities/shopify';
import { Store } from '../../../types/api/entities/store';
import { CartItem } from './cart-item';

interface StateProps {
  store: Store;
  checkout: ShopifyLocalCheckout;
}

interface DispatchProps {
  setLineItemQuantity(variantId: string, quantity: number): void;
}

const _Cart: FC<StateProps & DispatchProps & WithTranslation> = ({
  store,
  checkout,
  setLineItemQuantity,
  t,
}) => {
  const checkoutWebUrl = useMemo(() => {
    if (!store.shopifyShopOrigin || !checkout) {
      return undefined;
    }

    const path = checkout.lineItems
      .map((item) => `${item.variant.legacyResourceId}:${item.quantity}`)
      .join(',');

    return `https://${store.shopifyShopOrigin}/cart/${path}`;
  }, [store, checkout]);

  const primaryColor = store.theme?.primaryColor ?? '#78a12f';

  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: '1em',
        width: '100%',
        boxSizing: 'border-box',
        boxShadow: '0 8px 20px 0 rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
      }}
    >
      {checkout.lineItems.length === 0 && (
        <p
          style={{
            fontFamily: 'var(--font-family-primary)',
            fontSize: '15px',
            color: '#343434',
            textAlign: 'center',
          }}
        >
          {t('CartIsEmpty')}
        </p>
      )}

      {checkout.lineItems.map((item, index) => (
        <CartItem
          key={item.variant.id}
          item={item}
          setQuantity={setLineItemQuantity}
          style={{
            marginTop: index === 0 ? undefined : '8px',
          }}
        />
      ))}

      {checkout.lineItems.length > 0 && (
        <a
          style={{
            height: '44px',
            borderRadius: '4px',
            border: 'none',
            color: '#fbfbfb',
            padding: '0 16px',
            display: 'flex',
            boxSizing: 'border-box',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'var(--font-family-primary)',
            fontSize: '15px',
            width: '100%',
            marginTop: '8px',
            backgroundColor: primaryColor,
            cursor: 'pointer',
            textDecoration: 'none',
          }}
          href={checkoutWebUrl}
          target="_blank"
        >
          {t('Checkout')}
        </a>
      )}
    </div>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  store: state.entities.store,
  checkout: state.shopify.checkout,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setLineItemQuantity: (variantId: string, quantity: number) =>
    dispatch(setLineItemQuantity(variantId, quantity)),
});

export const Cart = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(_Cart));
