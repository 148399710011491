import React, { CSSProperties, FC, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import MDICartIcon from 'mdi-react/CartIcon';

import { StoreState } from '../../../store/state';
import { ShopifyLocalCheckout } from '../../../types/api/entities/shopify';
import { Store } from '../../../types/api/entities/store';
import { usePopper } from 'react-popper';
import { Cart } from './cart';
import { Dispatch } from 'redux';
import { setCartIsOpen } from '../../../store/actions/shopify/set-cart-is-open';

interface StateProps {
  checkout: ShopifyLocalCheckout;
  store: Store;
  cartIsOpen: boolean;
}

interface DispatchProps {
  setCartIsOpen(value: boolean): void;
}

export interface OwnProps {
  style?: CSSProperties;
}

export const CartIconComponent: FC<StateProps & OwnProps & DispatchProps> = ({
  style,
  store,
  checkout,
  cartIsOpen,
  setCartIsOpen,
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const toggleCartIsOpen = useCallback(() => {
    setCartIsOpen(!cartIsOpen);
  }, [setCartIsOpen, cartIsOpen]);

  return (
    <>
      <button
        ref={setReferenceElement}
        onClick={toggleCartIsOpen}
        style={{
          height: '44px',
          borderRadius: '4px',
          backgroundColor: store.theme?.primaryColor ?? '#78a12f',
          border: 'none',
          padding: '0 18px',
          fontFamily: 'var(--font-family-primary)',
          fontSize: '15px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textDecoration: 'none',
          ...style,
        }}
      >
        <MDICartIcon color="#fff" size={18} />
        <span style={{ color: '#fff', marginLeft: '8px' }}>{checkout.lineItems.length}</span>
      </button>

      {cartIsOpen && (
        <div
          ref={setPopperElement}
          style={{
            width: '100%',
            maxWidth: '480px',
            zIndex: 2,
            ...styles.popper,
          }}
          {...attributes.popper}
        >
          <Cart />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  cartIsOpen: state.shopify.cartIsOpen,
  checkout: state.shopify.checkout,
  store: state.entities.store,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setCartIsOpen: (cartIsOpen) => dispatch(setCartIsOpen(cartIsOpen)),
});

export const CartIcon = connect(mapStateToProps, mapDispatchToProps)(CartIconComponent);
